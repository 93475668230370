import Vue from 'vue'
import VueRouter from 'vue-router'

import HelloWorld from '../components/HelloWorld.vue'
import Home from '../views/HomeView.vue'
import Index from '../views/IndexView.vue'
import About from '../views/AboutView.vue'
import Contact from '../views/ContactView.vue'
import Des from '../views/DesView.vue'
import JoinUs from '../views/Join-Us.vue'

// vue注册路由
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/home',
    component: Home,
    redirect: '/index',
    meta: { title: '管理平台' },
    children: [
      { path: '/index', component: Index, meta: { title: '管理平台' } },
      { path: '/hello', component: HelloWorld, meta: { title: '管理平台' } },
      { path: '/about', component: About, meta: { title: '管理平台' } },
      { path: '/contact', component: Contact, meta: { title: '管理平台' } },
      { path: '/des', component: Des, meta: { title: '管理平台' } },
      { path: '/joinus', component: JoinUs, meta: { title: '管理平台' } },
      // { path: '/user', component: WxUser, meta: { title: '管理平台' } },
      // { path: '/lesson', component: LessonList, meta: { title: '管理平台' } },
      // { path: '/studies', component: StudyList, meta: { title: '管理平台' } },      
      // { path: '/setpwd', component: SetPwd, meta: { title: '管理平台' } },
      // { path: '/editor', component: Vue2Editor, meta: { title: '管理平台' } },
      // { path: '/particles', component: Particles, meta: { title: '管理平台' } },
      // { path: '/particles2', component: Particles2, meta: { title: '管理平台' } },
      // { path: '/bwguser', component: BwgList, meta: { title: '管理平台' } },
      // { path: '/bwgusermanage', component: BwgUser, meta: { title: '管理平台' } },
      // { path: '/selflesson', component: SelfLesson, meta: { title: '管理平台' } },
      // { path: '/subscribeLesson', component: SubscribeLesson, meta: { title: '管理平台' } },
      // { path: '/subscribePlan', component: SubscribePlan, meta: { title: '管理平台' } },
      // { path: '/konva', component: KonvaPage, meta: { title: '管理平台' } },
      // { path: '/ScaleLine', component: ScaleLine, meta: { title: '管理平台' } }      
    ]
  }
]

const router = new VueRouter({
  routes,
  // mode:'history'
})

export default router
