<template>
  <el-container>
    <el-header height="100px">
      <!-- <img src="img/home/德育树-01.png" height="88"> -->
      <img src="img/home/树苗.png" height="88">
      <!-- <img src="img/home/植物.png" height="88"> -->
      <!-- <img src="img/home/德育树-02.png" height="88"> -->
      <!-- <img src="img/home/logo.webp" > -->
      <span style="color:#aeae13;font-size:40px;">掌心智慧校园</span>
      <el-menu
        :default-active="activePath"
        mode="horizontal"
        background-color="#1B2B3D"
        text-color="white" 
        active-text-color="white" 
        :router="true"
        >
        <el-menu-item index="index">首页</el-menu-item>
        <el-menu-item index="des">功能介绍</el-menu-item>
        <el-menu-item index="joinus">诚邀区县级代理！</el-menu-item>
        <!-- <el-menu-item index="about">关于我们</el-menu-item> -->
        <!-- <el-menu-item index="about">教程</el-menu-item> -->
        <el-menu-item index="contact">联系方式</el-menu-item>
        <el-menu-item><a href="/school" target="_blank">管理员登录</a></el-menu-item>
        <el-menu-item><a href="/teacher" target="_blank">扫码登录</a></el-menu-item>
        <!-- <el-menu-item><a href="Help.html" target="_blank">帮助</a></el-menu-item> -->
        <!-- <el-submenu index="2">
          <template slot="title">平台登录</template>
          <el-menu-item><a href="/school" target="_blank">学校登录</a></el-menu-item>
          <el-menu-item><a href="/agent" target="_blank">分销平台登录</a></el-menu-item>
          <el-menu-item><a href="/platform" target="_blank">管理平台登录</a></el-menu-item>
        </el-submenu> -->
      </el-menu>
    </el-header>
    <el-main>
      <router-view></router-view>      
    </el-main>
    <el-footer height="60px"><span>掌心智慧校园 助力中国教育! Copyright © 2022 郑州雷傲网络科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2021038464号</a></span></el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 被激活导航地址
      activePath: "index",
    };
  },
  created() {
    //获取导航地址
    let a = window.sessionStorage.getItem("activePath");
    if(a != undefined && a!=null){
      this.activePath = a;
    }    
  },
  methods: {
  },
};
</script>

<style lang='less' scoped>
.el-header{
  display: flex;
  background-color: #1B2B3D;
  /* 内容垂直居中 */
  align-items: center;
  /* 内容水平居中 */
  justify-content: center;
}
.el-main{
  /* 减去header和footer的高度120; */
  height: calc(100vh - 160px);
  padding: 0;
  background: #1B2B3D; 
}

// ---------------------------滚动条样式设置----------------------
// 宽度
::-webkit-scrollbar{width:10px;} 
//背景色
::-webkit-scrollbar-track{background-color:#1B2B3D;}
//滑块颜色
::-webkit-scrollbar-thumb{background:linear-gradient(to bottom, #1B2B3D,#c0c0c0, #1B2B3D);}//线性从上到下渐变
//鼠标放上去时的滑块颜色
// ::-webkit-scrollbar-thumb:hover {background-color:#9c3}
//鼠标点击滑块滑动式，滑块的颜色
// ::-webkit-scrollbar-thumb:active {background-color:#00aff0}

.el-footer{
  color: white;
  display: flex;
  /* 内容底部对齐 */
  align-items:flex-end;
  padding-bottom: 10px;
  /* 内容水平居中 */
  justify-content: center;
  background: #1B2B3D;
  span{
    font-size: 14px;
    color: #aeae13;
  }
  a{
    color: #aeae13;
  }
}
.el-menu{
  // 控制与logo图片的间距
  margin-left: 200px;
  a{
    color: white;
    text-decoration: none;
  }
}
.el-menu .el-menu-item{
  height: 100%;
  display: flex;
  /* 内容垂直居中 */
  align-items: center;
}
.el-menu.el-menu--horizontal{
  border-bottom: none;
  height: 100%;
  display: flex;
  /* 内容垂直居中 */
  align-items: center;
}
</style>