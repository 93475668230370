import Vue from 'vue'
import App from './App.vue'

// 路由配置文件
import router from './router'

// element ui
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)

// 导入全局样式
import './assets/css/global.css'
// 自定义字体
import './assets/css/font.css'

Vue.config.productionTip = false

// -------------配置路由，显示标题-------------
// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
  
//   next()
// })

// -------------配置路由，记录当前的路径菜单-------------
router.afterEach((to) => {  
  console.log(to.path.substring(1))
  window.sessionStorage.setItem("activePath", to.path.substring(1));
})
// -----------------------------------------

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
